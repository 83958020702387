body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ####### Custom scroll bar settings ####### */
* {
  scrollbar-width: thin !important;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent !important;
}
*::-webkit-scrollbar {
  width: 0px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

.non-styled-link {
  text-decoration: none !important;
}
.non-styled-link::after {
  background-color: transparent;
  transform: none;
}
.non-styled-link:hover::after {
  transform: none;
}

.menu-link {
  text-decoration: none;
  font-size: 2rem;
  font-weight: 500;
  margin: 1rem 0;
  color: #eee;
}
.menu-link::after {
  background-color: transparent;
  transform: none;
}
.menu-link:hover::after {
  transform: none;
}

/* Hamberger icon animation */
.box {
  border-radius: 2px;
  position: absolute;
  top: 3rem;
  right: 0;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background: transparent;
}

.btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  cursor: pointer;
}

.span {
  display: block;
  width: 100%;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.3);
  border-radius: 3px;
  height: 5px;
  background: #FFE347;
  transition: all .3s;
  position: relative;
}

.span + .span {
  margin-top: 4px;
}

.active .span:nth-child(1) {
  animation: ease .7s top forwards;
}

.not-active .span:nth-child(1) {
  animation: ease .7s top-2 forwards;
}

.active .span:nth-child(2) {
  animation: ease .7s scaled forwards;
}

.not-active .span:nth-child(2) {
  animation: ease .7s scaled-2 forwards;
}

.active .span:nth-child(3) {
  animation: ease .7s bottom forwards;
}

.not-active .span:nth-child(3) {
  animation: ease .7s bottom-2 forwards;
}

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 12px;
    transform: rotate(0);
  }
  100% {
    top: 12px;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: 12px;
    transform: rotate(45deg);
  }
  50% {
    top: 12px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 12px;
    transform: rotate(0);
  }
  100% {
    bottom: 12px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 15px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 15px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
